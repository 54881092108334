import * as React from "react";
import { ToastContainer } from "react-toastify";

import { BaseStyle } from "atoms/BaseStyle";
import { Container } from "atoms/Container";
import { Header } from "atoms/Header";
import { HeaderActions } from "./HeaderActions";
import { Title } from "./Title";

type WithChildren = {
  children: React.ReactNode;
};

export function Layout({ children }: WithChildren) {
  return (
    <>
      <BaseStyle />
      <Header>
        <div />
        <Title />
        <HeaderActions />
      </Header>
      <Container>{children}</Container>
      <ToastContainer position="top-center" />
    </>
  );
}
