import React, { createContext, ReactNode, useContext, useState } from "react";
import { Analysis } from "domain/Analysis";

type AnalysisContextValue = {
  analysis: Analysis | null;
  setAnalysis: (value: Analysis) => void;
};

const AnalysisContext = createContext<AnalysisContextValue>({
  analysis: null,
  setAnalysis: () => {},
});

export function AnalysisContextProvider({ children }: { children: ReactNode }) {
  const [analysis, setAnalysis] = useState<Analysis | null>(null);

  return (
    <AnalysisContext.Provider value={{ analysis, setAnalysis }}>
      {children}
    </AnalysisContext.Provider>
  );
}

export function useAnalysis() {
  return useContext(AnalysisContext);
}
