import React, { useState } from "react";
import { Button, ButtonProps } from "./Button";

type DownloadButtonProps = {
  onClick: () => Promise<BlobPart | void>;
  label: string;
  loadingLabel: string;
  filename: string;
} & ButtonProps;

export function DownloadButton({
  onClick,
  label,
  loadingLabel,
  filename,
  ...buttonProps
}: DownloadButtonProps) {
  const [loading, setLoading] = useState(false);

  const handleClick = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);
    const data = await onClick();
    if (data) {
      downloadData(data, filename, "application/octet-stream");
    }
    setLoading(false);
  };

  return (
    <Button {...buttonProps} disabled={loading} onClick={handleClick}>
      {loading ? loadingLabel : label}
    </Button>
  );
}

// From https://github.com/kennethjiang/js-file-download/blob/master/file-download.js
function downloadData(
  blobData: BlobPart,
  filename: string,
  mime: string = "application/octet-stream"
) {
  var blob = new Blob([blobData], { type: mime });
  if (typeof window.navigator.msSaveBlob !== "undefined") {
    // IE workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    window.navigator.msSaveBlob(blob, filename);
  } else {
    var blobURL =
      window.URL && window.URL.createObjectURL
        ? window.URL.createObjectURL(blob)
        : window.webkitURL.createObjectURL(blob);
    var tempLink = document.createElement("a");
    tempLink.style.display = "none";
    tempLink.href = blobURL;
    tempLink.setAttribute("download", filename);

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === "undefined") {
      tempLink.setAttribute("target", "_blank");
    }

    document.body.appendChild(tempLink);
    tempLink.click();

    // Fixes "webkit blob resource error 1"
    setTimeout(function () {
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(blobURL);
    }, 200);
  }
}
