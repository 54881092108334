function handleBeforeUnload(e: BeforeUnloadEvent) {
  e.preventDefault();
  e.returnValue = "";
}

export function addBeforeUnloadListener() {
  window.addEventListener("beforeunload", handleBeforeUnload);
}

export function removeBeforeUnloadListener() {
  window.removeEventListener("beforeunload", handleBeforeUnload);
}
