import * as React from "react";
import { useTranslation } from "react-i18next";
import { Row } from "atoms/Row";
import { Languages } from "./Language/Languages";
import { RequestDemoButton } from "./RequestDemoButton";

export function HeaderActions() {
  const { t } = useTranslation();

  return (
    <Row justify="right" align="center">
      <Languages />
      <RequestDemoButton>{t("contact-us")}</RequestDemoButton>
    </Row>
  );
}
