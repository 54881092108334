import "core-js/stable";
import "react-app-polyfill/ie11";

import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "styled-components";
import { BrowserRouter, Route } from "react-router-dom";

import { Layout } from "molecules/Layout";

import "./i18n";
import "normalize.css";
import "react-toastify/dist/ReactToastify.css";
import { AnalysisContextProvider } from "organisms/AnalysisContext";

import NoTokenPage from "./pages/NoTokenPage";
import LoggedInPage from "./pages/LoggedInPage";

ReactDOM.render(
  <React.StrictMode>
    {/* i18n translations might still be loaded by the http backend use react's Suspense */}
    <Suspense fallback="Loading...">
      <ThemeProvider theme={{ mode: "dark" }}>
        <Layout>
          <BrowserRouter>
            <AnalysisContextProvider>
              <Route path="/" exact component={NoTokenPage} />
              <Route path="/:token" component={LoggedInPage} />
            </AnalysisContextProvider>
          </BrowserRouter>
        </Layout>
      </ThemeProvider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);
