import isEqual from "lodash/isEqual";
import uniq from "lodash/uniq";
import uniqBy from "lodash/uniqBy";
import uniqWith from "lodash/uniqWith";
import { useCallback, useState } from "react";
import { FileError, FileRejection } from "react-dropzone";
import { FileUploadError, SelectedFile } from "domain/SelectedFile";

export const MAXIMUM_STUDY_FILES = 10;
const TOO_MANY_FILES_ERROR: FileError = {
  code: "too-many-files",
  message: "Too many files selected",
};

const areFilesEqual = (previous: SelectedFile, next: SelectedFile) => {
  const prev = {
    name: previous.file.name,
    lastModified: previous.file.lastModified,
    size: previous.file.size,
  };
  const current = {
    name: next.file.name,
    lastModified: next.file.lastModified,
    size: next.file.size,
  };

  return isEqual(prev, current);
};

function computeTooManyFilesError(file: SelectedFile, index: number) {
  if (index < MAXIMUM_STUDY_FILES) {
    return {
      ...file,
      errors: file.errors.filter((e) => !isEqual(e, TOO_MANY_FILES_ERROR)),
    };
  }
  return {
    ...file,
    errors: uniq(file.errors.concat(TOO_MANY_FILES_ERROR)),
  };
}

export function useSelectedFiles() {
  const [files, setFiles] = useState<SelectedFile[]>([]);

  const onDrop = useCallback((accepted: File[], rejected: FileRejection[]) => {
    setFiles((prevFiles) => {
      return uniqWith(
        [
          ...prevFiles,
          ...accepted.map((file) => ({
            file,
            errors: [],
            uploadProgress: 0,
            uploaded: false,
            uploadErrors: [],
          })),
          ...rejected.map((file) => ({
            ...file,
            uploadProgress: 0,
            uploaded: false,
            uploadErrors: [],
          })),
        ],
        areFilesEqual
      ).map(computeTooManyFilesError);
    });
  }, []);

  const onDelete = useCallback(
    (file: SelectedFile) =>
      setFiles((prevFiles) =>
        prevFiles.filter((f) => f !== file).map(computeTooManyFilesError)
      ),
    []
  );

  const onUpdateProgress = useCallback(
    (file: SelectedFile, uploadProgress: number) => {
      setFiles((prevFiles) =>
        prevFiles.map((f) => {
          if (!areFilesEqual(f, file)) {
            return f;
          }

          return {
            ...f,
            uploadProgress,
          };
        })
      );
    },
    []
  );

  const onUploadSuccess = useCallback((file: SelectedFile, id: string) => {
    setFiles((prevFiles) =>
      prevFiles.map((f) => {
        if (!areFilesEqual(f, file)) {
          return f;
        }

        return {
          ...f,
          uploaded: true,
          id: id,
        };
      })
    );
  }, []);

  const onUploadError = useCallback(
    (file: SelectedFile, error: FileUploadError) => {
      setFiles((prevFiles) =>
        prevFiles.map((f) => {
          if (!areFilesEqual(f, file)) {
            return f;
          }

          return {
            ...f,
            uploaded: false,
            uploadProgress: 0,
            uploadErrors: uniqBy([...f.uploadErrors, error], "code"),
          };
        })
      );
    },
    []
  );

  return {
    files,
    onDrop,
    onDelete,
    onUpdateProgress,
    onUploadError,
    onUploadSuccess,
  };
}
