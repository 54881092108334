import styled, { keyframes } from "styled-components";
import { darkBlue, white } from "./Colors";

const loadKeyframe = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}`;

export const Spinner = styled.div`
  &,
  &:before,
  &:after {
    border-radius: 50%;
  }

  color: ${white};
  text-indent: -99999rem;
  margin: 3rem auto;
  position: relative;
  width: 10rem;
  height: 10rem;
  box-shadow: inset 0 0 0 0.25rem;
  transform: translateZ(0);

  &:before,
  &:after {
    position: absolute;
    content: "";
  }

  &:before {
    width: 5.2rem;
    height: 10.2rem;
    background: ${darkBlue};
    border-radius: 10.2rem 0 0 10.2rem;
    top: -0.1rem;
    left: -0.1rem;
    transform-origin: 5.1rem 5.1rem;
    animation: ${loadKeyframe} 2s infinite ease 1.5s;
  }

  &:after {
    width: 5.2rem;
    height: 10.2rem;
    background: ${darkBlue};
    border-radius: 0 10.2rem 10.2rem 0;
    top: -0.1rem;
    left: 4.9rem;
    transform-origin: 0.1rem 5.1rem;
    animation: ${loadKeyframe} 2s infinite ease;
  }
`;
