import * as React from "react";
import { ButtonLink } from "atoms/Button";

export function RequestDemoButton({ children }: React.PropsWithChildren<{}>) {
  return (
    <ButtonLink
      variant="primary"
      href="https://calendly.com/jeanbaptistedegery/30min"
      rel="noopener noreferrer"
      target="_blank"
    >
      {children}
    </ButtonLink>
  );
}
