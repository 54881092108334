import * as React from "react";
import { AxiosError, Canceler } from "axios";
import {
  SelectedFile,
  FileUploadError,
  hasNoErrors,
  BackendErrorCodes,
} from "domain/SelectedFile";
import { StudyInstance } from "domain/Study";
import { CancelableCall, uploadInstanceFile } from "services/upload";

function isUploadable(file: SelectedFile) {
  return !file.uploaded && hasNoErrors(file);
}

export function useFilesUpload(
  files: SelectedFile[],
  candidateStudyInstanceUid: string,
  onUpdateProgress: (file: SelectedFile, progress: number) => void,
  onUploadSuccess: (file: SelectedFile, id: string) => void,
  onUploadError: (file: SelectedFile, error: FileUploadError) => void
) {
  const [uploadingFile, setUploadingFile] = React.useState<SelectedFile | null>(
    null
  );

  const currentCall = React.useRef<CancelableCall<StudyInstance> | null>(null);

  // Detect a change in files list and set file to upload if no upload was pending.
  React.useEffect(() => {
    const nextFileToUpload = files.find(isUploadable);
    if (!uploadingFile && nextFileToUpload) {
      setUploadingFile(nextFileToUpload);
    }
  }, [uploadingFile, files]);

  // Start the upload when the uploading file is set.
  React.useEffect(() => {
    if (uploadingFile) {
      currentCall.current = uploadInstanceFile(
        candidateStudyInstanceUid,
        uploadingFile.file,
        (uploadProgress) => {
          onUpdateProgress(uploadingFile, uploadProgress);
        }
      );

      currentCall.current.promise
        .then((rsp) => {
          onUploadSuccess(uploadingFile, rsp.data.id);
          setUploadingFile(null);
          currentCall.current = null;
        })
        .catch((error: AxiosError) => {
          const errorCode =
            (error.response?.data?.code?.toLowerCase() as BackendErrorCodes) ||
            "technical_error";

          onUploadError(uploadingFile, {
            code: errorCode,
            message: error.response?.statusText ?? error.message,
          });
          setUploadingFile(null);
          currentCall.current = null;
        });
    }

    return () => {
      if (uploadingFile && currentCall.current) {
        currentCall.current.cancel();
      }
    };
  }, [
    uploadingFile,
    candidateStudyInstanceUid,
    onUpdateProgress,
    onUploadSuccess,
    onUploadError,
  ]);

  const cancelUpload: Canceler = () => {
    // This allows next file to be uploaded.
    setUploadingFile(null);
    currentCall.current?.cancel();
  };

  return {
    cancelUpload,
  };
}
