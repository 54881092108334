import * as React from "react";
import { useTranslation } from "react-i18next";
import { Column } from "atoms/Column";
import { Logo } from "atoms/Logo";
import { H1 } from "atoms/Typography";

export function Title() {
  const { t } = useTranslation();

  return (
    <Column>
      <Logo />
      <H1>{t("title")}</H1>
    </Column>
  );
}
