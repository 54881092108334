import styled from "styled-components";

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  & > * {
    flex: 1;
  }

  @media (max-width: 47.5rem) {
    & > :first-child {
      display: none;
    }

    & > :last-child {
      justify-content: center;
      margin-top: 1rem;
    }
  }
`;
