import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { AnalysisResult } from "molecules/Analysis/AnalysisResult";
import { useAnalysis } from "organisms/AnalysisContext";

export default function ResultPage({
  match,
  history,
}: RouteComponentProps<{ token: string }>) {
  const { analysis } = useAnalysis();

  if (!analysis) {
    history.push(`/${match.params.token}`);
    return null;
  }

  return <AnalysisResult analysis={analysis} />;
}
