import { useState } from "react";

export type INDICATIONS_STATUS = "accepted" | "declined" | "showing";

export function useIndications(initialStatus: INDICATIONS_STATUS = "showing") {
  const [indicationsStatus, setIndicationsStatus] = useState<
    INDICATIONS_STATUS
  >(initialStatus);

  const accept = () => {
    setIndicationsStatus("accepted");
  };

  const decline = () => {
    setIndicationsStatus("declined");
  };

  return {
    indicationsStatus,
    accept,
    decline,
  };
}
