import React, { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";
import { ButtonLink } from "atoms/Button";
import { Row } from "atoms/Row";
import { removeBeforeUnloadListener } from "../beforeUnloadListener";

const Explanations = styled.p`
  text-align: center;
  margin: 2rem 0 1rem;
`;

export default function NoTokenPage() {
  const { t } = useTranslation();

  useEffect(() => {
    removeBeforeUnloadListener();
  }, []);

  return (
    <>
      <Row justify="center">
        <Explanations>
          <Trans i18nKey="no-token" />
        </Explanations>
      </Row>
      <Row justify="center">
        <ButtonLink href="https://calendly.com/jeanbaptistedegery/30min">
          {t("request-demo")}
        </ButtonLink>
      </Row>
    </>
  );
}
