import * as React from "react";
import { FileError } from "react-dropzone";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Canceler } from "axios";

import { green } from "atoms/Colors";
import { Column } from "atoms/Column";
import { ReactComponent as CheckIcon } from "atoms/icons/check.svg";
import { ReactComponent as CloseIcon } from "atoms/icons/close.svg";
import { ReactComponent as TrashIcon } from "atoms/icons/trash.svg";
import { ProgressBar } from "atoms/ProgressBar";
import { Row } from "atoms/Row";
import { FileUploadError, SelectedFile } from "domain/SelectedFile";
import { TransparentButton } from "atoms/Button";

const Trash = styled(TrashIcon)`
  width: 1.5em;
  height: 1.5em;
  margin-right: 1rem;
  cursor: pointer;
`;

const Close = styled(CloseIcon)`
  width: 1.5em;
  height: 1.5em;
`;
const Check = styled(CheckIcon)`
  width: 1.5em;
  height: 1.5em;
  color: ${green};
`;

const Container = styled(Column).attrs((props) => ({
  align: "center",
  color: props.color || "inherit",
}))`
  background-color: ${(props) => props.color};
  width: 99%;
  border: 1px solid white;
  border-radius: 4px;
  margin-bottom: 0.75em;
`;

const Icons = styled.div`
  padding-right: 1em;
  padding-left: 1em;
`;

const FileInformation = styled(Column).attrs({
  flex: 3,
  align: "left",
})`
  padding-left: 1.5em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Content = styled(Row).attrs({
  align: "center",
})`
  width: 100%;
`;

const Errors = styled.ul`
  font-size: 0.75em;
  margin-top: 0;
`;

export type FileDisplayProps = SelectedFile & {
  onDelete?: () => void;
  onCancel?: Canceler;
};

function ErrorsList({ errors }: { errors: (FileError | FileUploadError)[] }) {
  const { t } = useTranslation();
  if (errors.length === 0) {
    return null;
  }

  return (
    <Errors>
      {errors.map((error) => (
        <li key={error.code}>{t(`error.code.${error.code}`)}</li>
      ))}
    </Errors>
  );
}

type FileDisplayIconsProps = {
  onDelete?: () => void;
  hasError: boolean;
  uploaded: boolean;
};

function FileDisplayIcons({
  onDelete,
  uploaded = false,
  hasError = false,
}: FileDisplayIconsProps) {
  const { t } = useTranslation();

  if (uploaded) {
    return (
      <Icons>
        <Column>
          <Check title={t("uploaded")} />
        </Column>
      </Icons>
    );
  }

  return (
    <Icons>
      <Column>
        <TransparentButton onClick={onDelete} title={t("delete")}>
          {hasError ? <Trash data-testid="trash-icon" /> : <Close />}
        </TransparentButton>
      </Column>
    </Icons>
  );
}

export function FileDisplay(props: FileDisplayProps) {
  const hasError = props.errors.length > 0 || props.uploadErrors.length > 0;

  const handleDelete = () => {
    if (!props.uploaded && props.uploadProgress > 0 && !hasError) {
      props.onCancel?.();
    }
    props.onDelete?.();
  };

  return (
    <Container color={hasError ? "red" : "inherit"}>
      <Content>
        <FileInformation>
          <p>{props.file.name}</p>
          <ErrorsList errors={[...props.errors, ...props.uploadErrors]} />
        </FileInformation>
        <FileDisplayIcons
          uploaded={props.uploaded}
          hasError={hasError}
          onDelete={handleDelete}
        />
      </Content>
      <ProgressBar value={props.uploadProgress} max={100} />
    </Container>
  );
}
