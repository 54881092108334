import styled from "styled-components";

type Alignment = "right" | "center" | "left";

type RowProps = {
  justify?: Alignment;
  align?: Alignment;
  margin?: number;
  flex?: number;
};

export const Row = styled.div<RowProps>`
  display: flex;
  justify-content: ${(props) => {
    switch (props.justify) {
      case "right":
        return "flex-end";
      case "center":
        return "center";
      case "left":
      default:
        return "flex-start";
    }
  }};
  align-items: ${(props) =>
    props.align === "center" ? "center" : "flex-start"};
  margin: 0 ${(props) => props.margin ?? 0}rem;
  ${(props) => props.flex && "flex: " + props.flex + ";"}
`;
