import * as React from "react";
import { Row } from "atoms/Row";
import { useTranslation } from "react-i18next";
import { Column } from "atoms/Column";
import { Button } from "atoms/Button";
import styled from "styled-components";
import { grey, red } from "atoms/Colors";

type IndicationsProps = {
  onAccept: () => void;
  onDecline: () => void;
};

const Container = styled(Column)`
  padding: 1rem;
  border: 1px solid ${grey};
  border-radius: 0.25rem;
  max-width: 36rem;
`;

const ButtonsRow = styled(Row)`
  width: 100%;
  margin: 1rem 0 0;
`;

const ActionButton = styled(Button)`
  &:first-child {
    margin-right: 1rem;
  }
`;

const IndicationTitle = styled.h2`
  color: ${red};
  font-size: 1.1rem;
  margin: 0 0 0.5rem;
`;

const IndicationWarningTitle = styled.h3`
  font-size: 1rem;
  color: ${red};
  text-transform: uppercase;
  margin: 1rem 0 0;
`;

const IndicationText = styled.p`
  margin: 0.25rem 0;
`;

export function Indications({ onAccept, onDecline }: IndicationsProps) {
  const { t } = useTranslation();

  return (
    <Container align="left">
      <IndicationTitle>{t("indications.title")}</IndicationTitle>
      <IndicationWarningTitle>
        {t("indications.warnings.0.title")}
      </IndicationWarningTitle>
      <IndicationText>{t("indications.warnings.0.content")}</IndicationText>
      <IndicationWarningTitle>
        {t("indications.warnings.1.title")}
      </IndicationWarningTitle>
      <IndicationText>{t("indications.warnings.1.content")}</IndicationText>
      <ButtonsRow justify="right">
        <ActionButton variant="secondary" onClick={onDecline}>
          {t("indications.decline")}
        </ActionButton>
        <ActionButton variant="primary" onClick={onAccept}>
          {t("indications.accept")}
        </ActionButton>
      </ButtonsRow>
    </Container>
  );
}
