import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { doubt, negative, notAvailable, positive } from "atoms/Colors";
import { Row } from "atoms/Row";
import {
  Analysis,
  AnalysisResultCode,
  AnalysisResultCodes as AnalysisResultType,
} from "domain/Analysis";
import { Button } from "atoms/Button";
import { downloadResultFile, getResultFile } from "services/getResults";
import { useAnalysis } from "organisms/AnalysisContext";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { Column } from "atoms/Column";
import { DownloadButton } from "atoms/DownloadButton";
import { Spinner } from "atoms/Spinner";
import { removeBeforeUnloadListener } from "../../beforeUnloadListener";

const DownloadResultButton = styled(DownloadButton)`
  margin: 2rem 0;
`;

function getResultColor(code: AnalysisResultCode) {
  switch (code) {
    case "POSITIVE":
      return positive;
    case "DOUBT":
      return doubt;
    case "NEGATIVE":
      return negative;
    default:
      return notAvailable;
  }
}

const GlobalResult = styled.h3<{ code: AnalysisResultCode }>`
  font-size: 2rem;
  color: ${(props) => getResultColor(props.code)};
`;

export type AnalysisResultProps = {
  analysis: Analysis;
};

function getGlobalResult(resultCodes: AnalysisResultType) {
  const globalResultCodes: AnalysisResultCode[] = [
    "POSITIVE",
    "DOUBT",
    "NOT_AVAILABLE",
    "NEGATIVE",
  ];

  return (
    globalResultCodes.find((code) => {
      return Object.values(resultCodes).some(
        (resultCode) => resultCode === code
      );
    }) || "NOT_AVAILABLE"
  );
}

function RestartButton() {
  const { t } = useTranslation();
  return (
    <Button onClick={() => window.location.reload()}>
      {t("result.new-study")}
    </Button>
  );
}

export function AnalysisResult({ analysis }: AnalysisResultProps) {
  const { t } = useTranslation();
  const params = useParams<{ studyInstanceUID: string }>();

  const { setAnalysis } = useAnalysis();

  function handleDownload() {
    return downloadResultFile(params.studyInstanceUID)
      .catch((_err) => {
        toast(t("download-failed"), { type: "error" });
      })
      .finally(() => {
        removeBeforeUnloadListener();
      });
  }

  const analysisFailedMessage = t("analysis-failed");

  useEffect(() => {
    let currentCall = getResultFile(params.studyInstanceUID);

    currentCall.promise
      .then(({ data }) => {
        setAnalysis(data);
      })
      .catch(() => {
        toast(analysisFailedMessage, { type: "error" });
      });

    return () => {
      if (currentCall) {
        currentCall.cancel();
      }
    };
  }, [analysisFailedMessage, params.studyInstanceUID, setAnalysis]);

  if (analysis.state === "FAILURE" || !analysis.resultCodes) {
    return (
      <Column align="center">
        <p>{t("analysis-failed")}</p>
        <RestartButton />
      </Column>
    );
  }

  const globalResult = getGlobalResult(analysis.resultCodes);
  const { resultCodes } = analysis;

  return (
    <>
      <Row justify="center">
        <GlobalResult code={globalResult}>
          {t(`result.code.${globalResult.toLowerCase()}`)}
        </GlobalResult>
      </Row>
      {Object.keys(resultCodes)
        .filter(
          (obsType) =>
            resultCodes[obsType] !== "NOT_AVAILABLE" &&
            resultCodes[obsType] !== "NEGATIVE"
        )
        .map((obsType) => (
          <Row justify="center" key={obsType}>
            {t(
              `result.${obsType.toLowerCase()}.${resultCodes[
                obsType
              ].toLowerCase()}`
            )}
          </Row>
        ))}
      <Column align="center">
        {analysis.state === "SUCCESS" ? (
          <>
            <DownloadResultButton
              data-testid="download-result"
              variant="primary"
              label={t(`result.download`)}
              loadingLabel={t(`result.downloading`)}
              filename={params.studyInstanceUID + ".zip"}
              onClick={handleDownload}
            />
            <RestartButton />
          </>
        ) : (
          <div>
            <Spinner />
            <Row justify="center">{t(`result.building-files`)}</Row>
          </div>
        )}
      </Column>
    </>
  );
}
