import * as React from "react";
import Axios from "axios";
import { Trans, useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";

import { Button } from "atoms/Button";
import { redAlert, white } from "atoms/Colors";
import { Column } from "atoms/Column";
import { Row } from "atoms/Row";
import { Analysis } from "domain/Analysis";
import { isSuccessfullyUploaded, SelectedFile } from "domain/SelectedFile";
import { Study } from "domain/Study";
import { FilesDisplay } from "molecules/Upload/FilesDisplay";
import { UploadZone } from "molecules/Upload/UploadZone";
import { useFilesUpload } from "./useFilesUpload";
import { MAXIMUM_STUDY_FILES, useSelectedFiles } from "./useSelectedFiles";
import { INDICATIONS_STATUS, useIndications } from "./useIndications";
import { Indications } from "molecules/Upload/Indications";
import { RequestDemoButton } from "molecules/RequestDemoButton";

const Introduction = styled.div`
  line-height: 1.2em;
`;

const Footer = styled(Introduction)`
  font-style: italic;
`;

const RightColumn = styled.section<{ displayed: boolean }>`
  margin-left: 2em;
  display: ${(props) => (props.displayed ? "block" : "none")};
`;
const PaddedColumn = styled(Column)`
  padding: 2em;
`;

const FilesCount = styled.span<{ count: number }>`
  color: ${(props) => (props.count > MAXIMUM_STUDY_FILES ? redAlert : white)};
`;

type UploadInstancesProps = Study & {
  initialIndicationStatus?: INDICATIONS_STATUS;
};

function areReadyForAnalysis(files: SelectedFile[]) {
  return files.length > 0 && files.every(isSuccessfullyUploaded);
}

function analyzeStudy(
  candidateStudyInstanceUID: string,
  files: SelectedFile[]
) {
  const request = {
    lang: "fr",
    images: files.map((f) => f.id),
  };

  return Axios.post<Analysis>(
    `/api/studies/${candidateStudyInstanceUID}/analysis`,
    request,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}

export function UploadInstances({
  candidateStudyInstanceUID,
  remaining,
  initialIndicationStatus = "showing",
}: UploadInstancesProps) {
  const { t } = useTranslation();
  const acceptedMimeTypes = ["", "application/dicom"];
  const history = useHistory();
  const { token } = useParams<{ token: string }>();
  const { indicationsStatus, accept, decline } = useIndications(
    initialIndicationStatus
  );

  const {
    files,
    onDrop,
    onDelete,
    onUpdateProgress,
    onUploadError,
    onUploadSuccess,
  } = useSelectedFiles();

  const { cancelUpload } = useFilesUpload(
    files,
    candidateStudyInstanceUID,
    onUpdateProgress,
    onUploadSuccess,
    onUploadError
  );

  const onSubmit = async () => {
    try {
      await analyzeStudy(candidateStudyInstanceUID, files);
      history.push(`/${token}/in-progress/${candidateStudyInstanceUID}`);
    } catch (error) {
      toast(t("analysis-failed"), { type: "error" });
    }
  };

  return (
    <>
      <Introduction>
        {/* Use Trans component to interpolate new lines */}
        <Trans i18nKey="introduction" />
      </Introduction>
      {indicationsStatus === "showing" && (
        <Row justify="center">
          <Indications onAccept={accept} onDecline={decline} />
        </Row>
      )}
      {indicationsStatus === "accepted" && (
        <>
          <Row justify="center">
            <section>
              <h4>{t("upload-files")}</h4>
              <UploadZone
                onDrop={onDrop}
                multiple
                noClick
                accept={acceptedMimeTypes}
              />
            </section>
            <RightColumn displayed={files.length > 0}>
              <h4>
                {t("selected-files")}{" "}
                <FilesCount count={files.length}>{files.length}</FilesCount> /{" "}
                {MAXIMUM_STUDY_FILES}
              </h4>
              <FilesDisplay
                files={files}
                onDelete={onDelete}
                onCancel={cancelUpload}
              />
            </RightColumn>
          </Row>
          <PaddedColumn align="center">
            <Button
              variant="primary"
              size="large"
              disabled={!areReadyForAnalysis(files)}
              onClick={onSubmit}
            >
              {t("analyze")}
            </Button>
            <p>
              {t("studies-remaining")} {remaining}
            </p>
          </PaddedColumn>
        </>
      )}
      {indicationsStatus === "declined" && (
        <Row justify="center">
          <RequestDemoButton>{t("request-demo")}</RequestDemoButton>
        </Row>
      )}
      <Footer>
        <Trans i18nKey="footer" />
      </Footer>
    </>
  );
}
