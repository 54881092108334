import React from "react";
import { SelectedFile } from "domain/SelectedFile";
import { FileDisplay } from "./FileDisplay";
import styled from "styled-components";
import { Canceler } from "axios";

const generateKey = (el: SelectedFile) =>
  el.file.name + el.file.size + el.file.lastModified;

type FilesDisplayProps = {
  files: SelectedFile[];
  onDelete?: (file: SelectedFile) => void;
  onCancel?: Canceler;
};

const Container = styled.div`
  width: 30em;
  height: 32em;
  overflow-y: auto;
`;

export function FilesDisplay(props: FilesDisplayProps) {
  return (
    <Container>
      {props.files.map((file) => (
        <FileDisplay
          onCancel={props.onCancel}
          onDelete={() => props.onDelete?.(file)}
          key={generateKey(file)}
          {...file}
        />
      ))}
    </Container>
  );
}
