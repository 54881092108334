import axios, { AxiosResponse, Canceler } from "axios";
import { StudyInstance } from "domain/Study";

export interface CancelableCall<T> {
  promise: Promise<AxiosResponse<T>>;
  cancel: Canceler;
}

type ProgressHandler = (progress: number) => void;

export function uploadInstanceFile(
  candidateStudyInstanceUid: string,
  file: File,
  onUploadProgress?: ProgressHandler
): CancelableCall<StudyInstance> {
  const cancelTokenSource = axios.CancelToken.source();

  const promise = axios.post<StudyInstance>(
    `/api/studies/${candidateStudyInstanceUid}/instances`,
    file,
    {
      cancelToken: cancelTokenSource.token,
      onUploadProgress: (event: ProgressEvent) => {
        onUploadProgress?.(Math.round((100 * event.loaded) / event.total));
      },
      headers: {
        "Content-Type": "application/dicom",
      },
    }
  );

  return {
    cancel: cancelTokenSource.cancel,
    promise,
  };
}
