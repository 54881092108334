import styled from "styled-components";

export const H1 = styled.h1`
  margin: 0;
  font-weight: 300;
  font-size: 1.5rem;
  letter-spacing: 0.25em;
`;

export const Introduction = styled.p``;
