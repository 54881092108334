import * as React from "react";
import axios, { CancelToken } from "axios";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Study } from "domain/Study";

export function useCandidateStudy() {
  const history = useHistory();
  const params = useParams<{ token?: string }>();
  const { t } = useTranslation();

  const [candidateStudy, setCandidateStudy] = React.useState<Study | null>(
    null
  );

  const token = params?.token;
  const invalidTokenMessage = t("error.token.invalid");
  const noMoreAnalysesMessages = t("error.no-more-analyses");
  const unexpectedErrorMessage = t("error.unexpected");

  React.useEffect(() => {
    async function initiateStudy(cancelToken: CancelToken) {
      try {
        const { data } = await axios.post<Study>("/api/studies", null, {
          cancelToken,
          headers: {
            Authorization: `Basic ${token}`,
          },
        });

        setCandidateStudy(data);
        axios.defaults.headers["Authorization"] = `Basic ${token}`;
      } catch (error) {
        if (error.response?.status === 401) {
          history.replace("/");
          toast(invalidTokenMessage, { type: "error" });
          return;
        }
        if (error.response?.status === 403) {
          history.replace("/");
          toast(noMoreAnalysesMessages, { type: "warning" });
          return;
        }
        toast(unexpectedErrorMessage, { type: "error" });
      }
    }

    const cancelTokenSource = axios.CancelToken.source();

    initiateStudy(cancelTokenSource.token);

    return () => {
      cancelTokenSource.cancel();
    };
  }, [
    token,
    invalidTokenMessage,
    history,
    unexpectedErrorMessage,
    noMoreAnalysesMessages,
  ]);

  return candidateStudy;
}
