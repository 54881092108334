import { FileRejection } from "react-dropzone";

export type BackendErrorCodes =
  | "entity_not_found"
  | "unsupported_modality"
  | "illegal_state"
  | "illegal_argument"
  | "missing_dicom_field"
  | "dicom_parsing"
  | "unsupported_photometric_interpretation"
  | "technical_error";

export interface FileUploadError {
  code: BackendErrorCodes;
  message: string;
}

export interface SelectedFile extends FileRejection {
  uploadProgress: number;
  uploaded: boolean;
  uploadErrors: FileUploadError[];
  id?: string;
}

export function hasNoErrors(file: SelectedFile) {
  return file.errors.length === 0 && file.uploadErrors.length === 0;
}
export function isSuccessfullyUploaded(file: SelectedFile) {
  return file.uploaded && hasNoErrors(file);
}
