import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Row } from "atoms/Row";
import { Spinner } from "atoms/Spinner";

const MainText = styled.span`
  font-size: 2rem;
  margin-bottom: 0.5rem;
`;

export function AnalysisSpinner() {
  const { t } = useTranslation();

  return (
    <div>
      <Spinner />
      <Row justify="center">
        <MainText>{t("in-progress.analysis")}</MainText>
      </Row>
      <Row justify="center">{t("in-progress.timing")}</Row>
    </div>
  );
}
