import styled, { css } from "styled-components";
import { red, white } from "./Colors";

type ButtonType = "primary" | "secondary";
type ButtonSize = "large" | "normal";

export type ButtonProps = {
  variant?: ButtonType;
  size?: ButtonSize;
};

const commonButtonStyle = css<ButtonProps>`
  background-color: ${(props) =>
    props.variant === "primary" ? red : "transparent"};
  border: 0.15em solid ${red};
  border-radius: 0.25em;
  color: ${(props) => (props.variant === "primary" ? white : red)};
  font-size: 1em;
  padding: 0.8em ${(props) => (props.size === "large" ? "4em" : "1.2em")};
`;

export const Button = styled.button<ButtonProps>`
  ${commonButtonStyle}
  cursor: pointer;

  &:disabled {
    cursor: inherit;
    background-color: ${(props) =>
      props.variant === "primary" ? "#EE8A8A" : "transparent"};
    color: ${(props) => (props.variant === "primary" ? white : "#EE8A8A")};
    border-color: #ee8a8a;
  }
`;

export const ButtonLink = styled.a<ButtonProps>`
  ${commonButtonStyle}
  text-decoration: none;
`;

export const TransparentButton = styled.button`
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  margin: 0;
  font-size: 1em;
  border: none;
  color: currentColor;
  transition: all 0.2s ease-in;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:focus,
  &:hover {
    outline: none;
    transform: scale(1.1);
  }
`;
