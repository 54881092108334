import theme from "styled-theming";

export const darkBlue = theme("mode", {
  dark: "#20223d",
});

export const white = theme("mode", {
  dark: "#ffffff",
});

export const red = theme("mode", {
  dark: "#ee5858",
});

export const redAlert = theme("mode", {
  dark: "red",
});

export const lightRed = theme("mode", {
  dark: "#ea9e9e",
});

export const grey = theme("mode", {
  dark: "#bcbcbc",
});

export const activeDark = theme("mode", {
  dark: "#4c4e63",
});

export const green = theme("mode", {
  dark: "#22bb33",
});

export const negative = theme("mode", {
  dark: "#19aa1f",
});

export const doubt = theme("mode", {
  dark: "#d56626",
});

export const positive = theme("mode", {
  dark: "#ef2f2f",
});

export const notAvailable = theme("mode", {
  dark: "#868686",
});
