import styled from "styled-components";
import { red } from "./Colors";

export const ProgressBar = styled.progress`
  &[value] {
    height: 2px;
    -webkit-appearance: none;
    appearance: none;
  }
  &[value]::-webkit-progress-bar {
    background-color: transparent;
  }
  &::-webkit-progress-value {
    background-color: ${red};
  }
  &[value]::-moz-progress-bar {
    background-color: ${red};
  }

  height: 3px;
  margin: 0em 0em 0.5em;
  width: 100%;
  border: none;
  -webkit-appearance: none;
  background-color: transparent;
`;
