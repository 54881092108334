import React from "react";
import { DropzoneOptions, useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { Button } from "atoms/Button";
import styled from "styled-components";
import { red } from "atoms/Colors";
import { Column } from "atoms/Column";
import { ReactComponent as UploadIcon } from "atoms/icons/upload.svg";
import { MAXIMUM_STUDY_FILES } from "organisms/useSelectedFiles";

type ZoneProps = {
  borderStyle: "solid" | "dashed";
  background?: string;
};

const Zone = styled(Column)<ZoneProps>`
  padding: 1em;
  height: 30em;
  width: 36em;
  justify-content: center;
  border: 2px ${(props) => props.borderStyle} ${red};
  border-radius: 4px;
  background: ${(props) => props.background || "transparent"};
`;

export function UploadZone(props: DropzoneOptions) {
  const { t } = useTranslation();

  const dropzoneState = useDropzone(props);

  const inactive = (
    <Zone borderStyle="dashed">
      <p>{t("drag-n-drop")}</p>
      <p>{t("or")}</p>
      <Button variant={"secondary"} onClick={dropzoneState.open}>
        {t("browse")}
      </Button>
      <p>
        {t("max-images")} {MAXIMUM_STUDY_FILES}
      </p>
    </Zone>
  );

  const active = (
    <Zone borderStyle="solid" background="rgba(255, 255, 255, 0.05)">
      <UploadIcon />
      <p>{t("drop")}</p>
    </Zone>
  );

  return (
    <div {...dropzoneState.getRootProps()}>
      <input data-testid="files-input" {...dropzoneState.getInputProps()} />
      {dropzoneState.isDragActive ? active : inactive}
    </div>
  );
}
