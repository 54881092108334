import * as React from "react";
import { Row } from "atoms/Row";
import { LanguageSelector } from "./LanguageSelector";

export function Languages() {
  return (
    <Row margin={3}>
      <LanguageSelector languageCode="fr" />
      <LanguageSelector languageCode="en" />
    </Row>
  );
}
