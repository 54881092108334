import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { AnalysisSpinner } from "molecules/Analysis/AnalysisSpinner";
import { useAnalysis } from "organisms/AnalysisContext";
import { getResultCodes } from "services/getResults";

export default function InProgressPage({
  history,
  match,
}: RouteComponentProps<{
  studyInstanceUID: string;
  token: string;
}>) {
  const { studyInstanceUID, token } = match.params;

  const { setAnalysis } = useAnalysis();

  useEffect(() => {
    if (!studyInstanceUID) {
      history.push(`/${token}`);
      return;
    }

    let currentCall = getResultCodes(studyInstanceUID);

    currentCall.promise
      .then(({ data }) => {
        setAnalysis(data);
        history.push(`/${token}/result/${studyInstanceUID}`);
      })
      .catch(() => {
        history.push(`/${token}`);
      });

    return () => {
      if (currentCall) {
        currentCall.cancel();
      }
    };
  }, [history, setAnalysis, studyInstanceUID, token]);

  return <AnalysisSpinner />;
}
