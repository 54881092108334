import styled from "styled-components";

type Alignment = "right" | "center" | "left";

type ColumnProps = {
  flex?: number;
  align?: Alignment;
};

export const Column = styled.div<ColumnProps>`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: ${(props) => {
    switch (props.align) {
      case "right":
        return "flex-end";
      case "left":
        return "flex-start";
      case "center":
      default:
        return "center";
    }
  }};
  ${(props) => props.flex && `flex: ${props.flex};`}
`;
