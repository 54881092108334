import React, { useEffect } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import { UploadInstances } from "organisms/UploadInstances";
import { useCandidateStudy } from "organisms/useCandidateStudy";
import InProgressPage from "./InProgressPage";
import ResultPage from "./ResultPage";
import {
  addBeforeUnloadListener,
  removeBeforeUnloadListener,
} from "../beforeUnloadListener";

export default function LoggedInPage() {
  const { path } = useRouteMatch();

  const study = useCandidateStudy();
  const hasStudy = !!study;

  useEffect(() => {
    // Display a message when window is unloaded (reload or close window)
    if (hasStudy) {
      addBeforeUnloadListener();
    }

    return () => {
      removeBeforeUnloadListener();
    };
  }, [hasStudy]);

  // Display nothing while the token is fetched
  if (!study) {
    return null;
  }

  return (
    <>
      <Switch>
        <Route path={path} exact>
          <UploadInstances {...study} />
        </Route>
        <Route
          path={`${path}/in-progress/:studyInstanceUID`}
          component={InProgressPage}
        />
        <Route
          path={`${path}/result/:studyInstanceUID`}
          component={ResultPage}
        />
      </Switch>
    </>
  );
}
