import axios, { AxiosResponse } from "axios";
import { CancelableCall } from "./upload";
import { Analysis, AnalysisState } from "domain/Analysis";

const pollAnalysis = (...resolvedStates: AnalysisState[]) => (
  candidateStudyInstanceUid: string,
  pollingInterval: number = 5000
) => {
  let currentCall: CancelableCall<Analysis>;

  const promise: Promise<AxiosResponse<Analysis>> = new Promise(
    (resolve, reject) => {
      pollingInterval = setInterval(async () => {
        currentCall?.cancel();
        currentCall = getAnalysis(candidateStudyInstanceUid);
        currentCall.promise
          .then((axiosResponse) => {
            const { data } = axiosResponse;
            if (resolvedStates.includes(data?.state)) {
              clearInterval(pollingInterval);
              return resolve(axiosResponse);
            }
          })
          .catch((error) => {
            clearInterval(pollingInterval);
            return reject(error);
          });
      }, pollingInterval);
    }
  );

  const cancel = () => {
    if (currentCall) {
      currentCall.cancel();
    }
    clearInterval(pollingInterval);
  };

  return {
    cancel,
    promise,
  };
};

function getAnalysis(
  candidateStudyInstanceUid: string
): CancelableCall<Analysis> {
  const cancelTokenSource = axios.CancelToken.source();

  const promise = axios.get<Analysis>(
    `/api/studies/${candidateStudyInstanceUid}/analysis`,
    {
      cancelToken: cancelTokenSource.token,
    }
  );

  return {
    cancel: cancelTokenSource.cancel,
    promise,
  };
}

export const getResultCodes = pollAnalysis(
  "RESULT_CODES_AVAILABLE",
  "SUCCESS",
  "FAILURE"
);
export const getResultFile = pollAnalysis("SUCCESS", "FAILURE");

export function downloadResultFile(candidateStudyInstanceUid: string) {
  return axios
    .get<BlobPart>(`/api/studies/${candidateStudyInstanceUid}/result`, {
      responseType: "blob",
    })
    .then((response) => response.data);
}
