import { createGlobalStyle } from "styled-components";
import { darkBlue, white } from "./Colors";

export const BaseStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 1rem 2rem;
    font-family: Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif, sans-serif;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: ${darkBlue};
    color: ${white};
  }
`;
