import * as React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

type Props = {
  languageCode: string;
};

const LanguageButton = styled.button`
  color: currentColor;
  background-color: transparent;
  border: none;
  text-transform: uppercase;
  margin: 0;
  padding: 0 0.5rem;
  cursor: pointer;
  font-size: 1.25rem;

  &:not(:last-child) {
    border-right: 2px solid currentColor;
  }
`;

export function LanguageSelector({ languageCode }: Props) {
  const { i18n } = useTranslation();

  const changeLanguage = (e: React.SyntheticEvent) => {
    e.preventDefault();
    i18n.changeLanguage(languageCode);
  };

  return (
    <LanguageButton onClick={changeLanguage}>{languageCode}</LanguageButton>
  );
}
